<template>
  <div>
    <div class="header_zw"></div>
    <div class="header">
      <div class="header_c">
        <div class="head_l">
          <a class="head_l11" href="javascript:void(0)">
            <router-link :to="{ path: '/' }">
              <img src="../images/logo1.png" />
            </router-link>
          </a>
        </div>
        <div class="head_r">
          <div class="head_rt">
            <a href="javascript:void(0)">
              <img src="../images/logo4.png" />
            </a>
          </div>
          <div class="head_rt2">
            <div class="head_rt21">
              <div class="head_rt21a" v-if="!$UserID">
                <a href="javascript:void(0)" @click="toLogin()">登录</a>
                <a href="javascript:void(0)" @click="toRegister()">注册</a>
              </div>
              <div class="head_rt21b" v-if="$UserID">
                <div class="head_rt21b1">
                  <a href="javascript:void(0)">
                    <img
                      v-if="!userInfo.Avatar"
                      src="../images/morentouxiang.png"
                    />
                    <img
                      v-if="userInfo.Avatar"
                      :src="$ImgUrl + userInfo.Avatar"
                    />
                  </a>
                  <div class="head_rt21b1_fix_x">
                    <div class="head_rt21b1_fix">
                      <div class="head_rt21b1_fixc">
                        <ul>
                          <li>
                            <a href="javascript:void(0)" @click="toUserCenter()"
                              >个人中心</a
                            >
                          </li>
                          <li>
                            <a href="javascript:void(0)" @click="ExitUser()"
                              >退出登录</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="head_rt21b2">
                  <a href="javascript:void(0)" @click="toMessageList()">
                    <img src="../images/wd_ico.png" />
                    <p v-if="messageCount > 0">
                      {{ messageCount > 0 ? messageCount : "" }}
                    </p>
                  </a>
                </div>
              </div>
              <div class="head_rt21b3">
                <img src="../images/header_ico2.png" />
              </div>
              <div class="header2_searchbox">
                <div class="header2_searchbox1">
                  <el-select
                    v-model="searchType"
                    popper-class="header_searchType"
                  >
                    <el-option
                      v-for="classitem in classOptions"
                      :key="classitem.value"
                      :label="classitem.label"
                      :value="classitem.value"
                    ></el-option>
                  </el-select>
                  <input
                    v-model="keyWord"
                    class="cse"
                    placeholder="请输入搜索内容"
                    type="text"
                  />
                  <a href="javascript:void(0)" @click="toSearchList()">
                    <img src="../images/search_ico2.png" />
                  </a>
                </div>
              </div>
            </div>
            <div class="head_rt22">
              <div class="head_rt22l">
                <el-select
                  v-model="searchType"
                  popper-class="header_searchType"
                >
                  <el-option
                    v-for="classitem in classOptions"
                    :key="classitem.value"
                    :label="classitem.label"
                    :value="classitem.value"
                  ></el-option>
                </el-select>
                <input
                  v-model="keyWord"
                  type="text"
                  placeholder="请输入搜索内容"
                />
                <a href="javascript:void(0)" @click="toSearchList()">搜索</a>
              </div>
              <a href="javascript:void(0)" @click="toSeniorSearch()"
                >高级检索</a
              >
            </div>
          </div>
          <div class="nav">
            <ul>
              <li class="nav_cur">
                <a href="javascript:void(0)" @click="toIndex()">首 页</a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="toIndexCatalogIndex()"
                  >索引目录</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="toEntryIndex()"
                  >知识条目库</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="toSearchAchievtIndex()"
                  >研究成果</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="toLegacyIndex()">遗 珍</a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="toAnLiteratureList()"
                  >古文献</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="toDynamicList()"
                  >学界动态</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" @click="toNewsList()">新闻资讯</a>
              </li>
              <!-- <li>
								<a href="javascript:void(0)" @click="toManuscript()">在线投稿</a>
							</li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import CheckInService from "../api/CheckInService.js";
export default {
  props: ["childIndex", "val"],
  data() {
    return {
      userInfo: "",
      messageCount: 0,
      searchType: "0",
      classOptions: [
        { value: "0", label: "标题" },
        { value: "1", label: "全文" },
        { value: "2", label: "关键词" },
      ],
      keyWord: "",
    };
  },
  methods: {
    GetUserInfo() {
      //获取用户信息
      var that = this;
      if (that.$userType == 1) {
        //机构用户
        CheckInService.GetUserOrgInfo(that.$UserID).then((res) => {
          if (res.Tag == 1) {
            that.userInfo = res.Result;
          }
        });
        // CheckInService.LogoutMechanicalUserOnline(that.$UserID,window.localStorage.getItem('DHToken')).then(res => { // 机构用户注册在线状态
        // 	if(res.Tag === 1) {
        // 		Vue.prototype.$orgUserOnline = true;
        // 		console.log("机构用户注册在线状态成功");
        // 	}
        // })
      } else {
        CheckInService.GetUserPerInfo(that.$UserID).then((res) => {
          // 普通用户
          if (res.Tag == 1) {
            that.userInfo = res.Result;
          }
        });
      }
    },
    GetMessageList() {
      //获取消息
      var that = this;
      CheckInService.GetMessageList(1, 10, that.$UserID).then((res) => {
        if (res.Tag == 1) {
          res.Result.forEach((item) => {
            if (item.RecipientState == 0) {
              that.messageCount = that.messageCount + 1;
            }
          });
        }
      });
    },
    toSeniorSearch() {
      //高级检索
      this.$router.push({
        path: "/SeniorSearch",
      });
    },
    toIndex() {
      //首页
      this.$router.push({
        path: "/",
      });
    },
    toIndexCatalogIndex() {
      //索引目录
      this.$router.push({
        path: "/IndexCatalogIndex",
      });
    },
    toEntryIndex() {
      this.$router.push({
        path: "/EntryIndex",
      });
    },
    toSearchAchievtIndex() {
      //研究成果
      this.$router.push({
        path: "/SearchAchievtIndex",
      });
    },
    toLegacyIndex() {
      //遗珍
      this.$router.push({
        path: "/LegacyIndex",
      });
    },
    toLogin() {
      //登录
      this.$router.push({
        path: "/Login",
      });
    },
    toRegister() {
      //注册
      this.$router.push({
        path: "/Register",
      });
    },
    toAnLiteratureList() {
      //古文献列表
      this.$router.push({
        path: "/AnLiteratureList",
      });
    },
    toDynamicList() {
      //学界动态
      this.$router.push({
        path: "AcademicDynamicsList",
      });
    },
    toNewsList() {
      //新闻资讯
      this.$router.push({
        path: "NewsList",
      });
    },
    toUserCenter() {
      //个人中心
      if (this.$userType == 0) {
        //个人用户
        this.$router.push({
          path: "PerUserInfo",
        });
      } else {
        this.$router.push({
          path: "/OrgUserInfo",
        });
      }
    },
    toManuscript() {
      //在线投稿
      this.$router.push({
        path: "/ManuscriptEditOne",
      });
    },
    toMessageList() {
      //消息列表
      this.$router.push({
        path: "/UserMessageList",
      });
    },
    ExitUser() {
      //退出登录
      if (this.$userType == 1) {
        // 机构用户
        CheckInService.LogoutMechanUserOnline(
          this.$UserID,
          window.localStorage.getItem("DHToken")
        ).then((res) => {});
      } else {
        // 普通用户
        CheckInService.LogoutUserOnline(
          this.$UserID,
          window.localStorage.getItem("DHToken")
        ).then((res) => {});
      }
      window.localStorage.removeItem("DHuserID");
      window.localStorage.removeItem("DHuserType");
      window.localStorage.removeItem("DHServiceType");
      window.localStorage.removeItem("DHToken");
      Vue.prototype.$UserID = "";
      Vue.prototype.$userType = "";
      Vue.prototype.$ServiceType = "";
      this.$router.push({
        path: "/Login",
      });
    },
    toSearchList() {
      //搜索
      if (this.keyWord == "") {
        this.$alert("请输入搜索内容", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      var search = {};
      search.searchCon = [
        { type: this.searchType, word: this.keyWord, operator: "1" },
      ];
      search.resType = [];
      search.startDate = "";
      (search.endDate = ""),
        this.$router.push({
          path: "SearchResult",
          query: {
            searchType: 0,
            searchCon: JSON.stringify(search),
          },
        });
    },
  },
  created() {
    console.log("$UserID:" + this.$UserID);
    // 获取用户信息
    if (this.$UserID) {
      this.GetUserInfo();
    } else {
      // this.$alert('请先登录系统', '提示', {
      // 	confirmButtonText: '确定',
      // 	callback: action => {
      // 		if (action == "confirm") {
      // 			this.$router.push({
      // 				path: '/Login'
      // 			});
      // 		}
      // 	}
      // });
    }
  },
  mounted() {
    var i = $("#header_id").val(); //1首页      2二级首页     3其他页
    if (this.val == 1) {
      $(".header").addClass("index_header");
    } else if (this.val == 2) {
      $(".header").addClass("index_header");
      // $(".header").addClass("sec_header");
    } else {
      $(".header_zw").show();
    }
    $(window).scroll(function() {
      if ($(window).scrollTop() > 500) {
        $(".header").addClass("fixed_header");
      } else {
        $(".header").removeClass("fixed_header");
        $(".header2_searchbox").hide(300);
      }
    });

    $(".head_rt21b3").click(function() {
      $(".header2_searchbox").toggle();
    });

    //------------------------------------

    $(".nav ul li").removeClass("nav_cur");
    $(".nav ul li")
      .eq(this.childIndex)
      .addClass("nav_cur");
  },
};
</script>

<style>
.head_rt22l .el-select {
  float: left;
  width: 70px;
}
.head_rt22l .el-input__inner {
  border: none !important;
  color: #af967f !important;
  background-color: inherit !important;
  height: 38px !important;
  line-height: 38px !important;
  border-radius: 4px 0 0 4px !important;
  padding: 0 5px !important;
}
.header2_searchbox1 .el-select {
  width: 70px;
  float: left;
}
.header2_searchbox1 .el-input__inner {
  border-color: #af967f !important;
  color: #fff !important;
  background-color: #af967f !important;
  height: 36px !important;
  line-height: 36px !important;
  border-radius: 4px 0 0 4px !important;
  padding: 0 5px !important;
}
</style>
